import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";


function Banner({data}) {
    return (
        <section className="services_banner banner_casestudy">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="m-auto text-center">
                        <div className="banenr_content">
                            <div className="example center text-center" data-text="SUNN DAO">
                                <h1 style={{
                                    color: `${checkData(data, 'values_color')}`
                                }}>{HtmlParser(checkData(data, 'banner_title'))}</h1>
                            </div>
                            <div>
                                {HtmlParser(checkData(data, 'banner_description'))}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Banner;
